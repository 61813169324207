import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import ProductInfo from '../components/productInfo.js';
import ProductSpec from '../components/productSpec.js';
import Progress from '../components/progress.js';

function Product(props) {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(global.config.api_server+'product?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const currentProduct = res.data.product;
        if(currentProduct === null || currentProduct === undefined){
          navigate("/");
        }else{
          setProduct(currentProduct);
          setLoading(false);
          if(currentProduct.seo_title){
            document.title = currentProduct.seo_title;
          }else if(currentProduct.seo_description){
            document.getElementsByTagName('meta')["description"].content = currentProduct.seo_description;
          }
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/");
    });
  }, []);

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container">
        <ProductInfo id={id} product={product}/>
        <ProductSpec id={id} product={product}/>
      </div>
    )
  }
}

export default Product;