import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import ProductTile from './productTile.js';
import { Paginator } from '../components/paginator.js';

function ProductList(props) {
  const MIN = global.config.price_filter_min;
  const MAX = global.config.price_filter_max;
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterVersion, setFilterVersion] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(MAX);
  const [minSubmitPrice, setMinSubmitPrice] = useState(0);
  const [maxSubmitPrice, setMaxSubmitPrice] = useState(MAX);
  const [offset, setOffset] = useState(0);
  let tempFilters = filters;

  useEffect(()=>{
    const filterTagString = [];
    if(props.tags){
      for (const t of props.tags) {
        if(t.tag_id){
          filterTagString.push(t.tag_id);
        }else{
          filterTagString.push(t.tag);
        }
      }
    }
    Object.keys(filters).map((k,index) => {
      const filterTagStringTemp = [];
      filters[k].tags.map(t => {
        if(t.selected){
          filterTagStringTemp.push(t.tag_id);
        }
      });
      if(filterTagStringTemp.length > 0){
        filterTagString.push(filterTagStringTemp.toString().replace(",","|"));
      }
    });
    let priceFilterString = "";
    if(minSubmitPrice != MIN || maxSubmitPrice != MAX){
      priceFilterString = "&minPrice="+minSubmitPrice+"&maxPrice="+maxSubmitPrice;
    }
    setLoading(true);
    axios.get(global.config.api_server+'products?detail=1&tags='+filterTagString.toString()+'&token='+localStorage.getItem("token")+priceFilterString) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setProducts(res.data.products);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });
  }, [filterVersion, props.tags]);

  useEffect(()=>{
    setFilterLoading(true);
    axios.get(global.config.api_server+'tags?filter=1&token='+localStorage.getItem("token")) 
    .then(res => {
      setFilterLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        computeFilter(res.data.tags);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setFilterLoading(false);
      alertService.error(err);
    });
  }, []);

  const computeFilter = (tags) => {
    const temp = {};
    if(tags != undefined && tags.length > 0){
      for (const t of tags) {
        const filterName = t.filter.trim().toLowerCase().replace(" ", "_");
        let array = [];
        if(temp[filterName] && temp[filterName].tags != undefined){
          array = temp[filterName].tags
        }else if(temp[filterName] == undefined){
          temp[filterName] = {label: t.filter};
        }
        array.push(t);
        temp[filterName].tags = array;
      }
      setFilters(temp);
    }
  }

  const filterChange = (e, t) => {
    const filterName = t.filter.trim().toLowerCase().replace(" ", "_");
    const tempTags = tempFilters[filterName].tags;
    for(const temp of tempTags){
      if(t.tag_id == temp.tag_id){
        if(e.target.checked){
          temp.selected = true;
        }else{
          temp.selected = false;
        }
      }
    }
    tempFilters[filterName].tags = tempTags;
  }

  const applyFilter = () => {
    let version = filterVersion;
    version++;
    setFilters(tempFilters);
    setMinSubmitPrice(minPrice);
    setMaxSubmitPrice(maxPrice);
    setFilterVersion(version);
  }

  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  const paginated_products = [];
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  for (const u of products) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_products.push(u);
    }
  }
  
  return(
    <div className="row">
      <div className="col-md-12">
        {props.tags.map(t =>
          <span key={t.tag_id} className={'mr-2 mb-4 badge badge-'+theme()}>{t.label}</span>
        )}
        {minSubmitPrice != MIN || maxSubmitPrice != MAX ? (
          <span className={'mr-2 mb-4 badge badge-'+theme()}>Price: {minPrice} - {maxPrice}</span>
        ):null }
        {Object.keys(filters).map((k,index) =>
          <span key={k}>
          {filters[k].tags.map(t =>
            <span key={t.tag_id}>
              {t.selected && <span key={t.tag_id} className={'mr-2 mb-4 badge badge-'+theme()}>{t.label}</span>}
            </span>
          )}
          </span>
        )}
      </div>
      <div className="col-md-4">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <button className={'btn btn-icon-split float-right btn-'+theme()} onClick={() => applyFilter()}>
              <span className="icon text-white-50">
                <i className="fas fa-check"></i>
              </span>
              <span className="text">Apply</span>
            </button>
            <h6 className={'mt-2 font-weight-bold text-'+theme()}>Filters</h6>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    Price
                  </div>
                  <div className="card-body">
                    <label for="min-price">Minimum - {minPrice}</label>
                    <input type="range" value={minPrice} className={"custom-range range-"+theme()} min={MIN} max={maxPrice} step="1" id="min-price" onChange={(e) => setMinPrice(e.target.value)}/>
                    <br/>
                    <label for="max-price">Maximum - {maxPrice}</label>
                    <input type="range" value={maxPrice} className={"custom-range range-"+theme()} min={minPrice} max={MAX} step="1" id="max-price" onChange={(e) => setMaxPrice(e.target.value)}/>
                  </div>
                </div>
              </div>
              {filterLoading === true ? (
                <Progress label='Loading' small={true}/>
              ):null}
              {Object.keys(filters).map((k,index) =>
                <div className="col-sm-12" key={k}>
                  <div className="card mb-4">
                    <div className="card-header py-3">
                      {filters[k].label}
                    </div>
                    <div className="card-body">
                    {filters[k].tags.map(t =>
                      <div key={t.tag_id}>
                        <input type="checkbox" className='mr-2' onChange={(e) => filterChange(e, t)}/>{t.label}
                      </div>
                    )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="card shadow mb-4">
          <div className="card-header py-3">        
            <h6 className={'mt-2 font-weight-bold text-'+theme()}>Product List</h6>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              {loading === true ? (
                <Progress label='Saving' small={true}/>
              ):null}
              {paginated_products.map(p =>
                <div className="col-md-4" key={p.product_id}>
                  <ProductTile product={p}/>
                </div>
              )}
              <div className="col-md-12">
                {products.length > 0 ? (
                  <Paginator
                    itemCount={total}
                    itemsPerPage={global.config.items_per_page}
                    showNextPages={2}
                    showPrevPages={2}
                    filterTotal={products.length}
                    grandTotal={products.length}
                    onClick={onPageChange.bind(this)}
                  />
                ):(
                  <p>No Products</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ProductList;