import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Cart(props) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    axios.get(global.config.api_server+'products?detail=1&task=cart&token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setProducts(res.data.products);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });
  }, []);

  const updateCartEntry = (v, pid) => {
    const tempProducts = [];
    for(const p of products){
      if(p.product_id == pid){
        if(parseInt(v) <= p.quantity){
          p.cart_quantity = v;
        }else{
          alertService.error("Quantity not available");
        }
        tempProducts.push(p);
      }
    }
    setProducts(tempProducts);
  }

  const deleteCartEntry = (pid) => {
    axios.get(global.config.api_server+'products?detail=1&task=cart&subtask=delete&id='+pid+'&token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setProducts(res.data.products);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });
  }

  const submitCart = () => {
    const tempProducts = [];
    for(const p of products){
      tempProducts.push(p.product_id + "|" + p.cart_quantity);
    }
    axios.post(global.config.api_server+'products',createFormData({
      cart: tempProducts.toString(),
      subtask: 'update',
      task: 'cart',
      token: localStorage.getItem("token")
    })).then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        alertService.success('Redirecting...');
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      setLoading(false);
    });
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="card shadow mb-4">
              <div className="card-header py-3">        
                <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Cart</h6>
              </div>
              <div className="card-body">
                <div className="row justify-content-center">
                  {products.length > 0 && (
                    <div>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {products.map(p =>
                          <tr key={p.product_id}>
                            <td>
                              {p.documents.map(doc =>
                                <span key={doc.document_id}>
                                  {doc.type === 'Main'? (
                                    <img className="cart" src={global.config.asset_server + doc.uri}/>
                                  ):null}
                                </span>
                              )}
                            </td>
                            <td>
                              {p.label}
                            </td>
                            <td>
                              {p.price}
                            </td>
                            <td>
                              <input type="number" min="1" value={p.cart_quantity} onChange={(e) => updateCartEntry(e.target.value, p.product_id)}/>
                            </td>
                            <td>
                              <button className={'btn btn-icon-split btn-'+theme()} onClick={(e) => deleteCartEntry(p.product_id)}>
                                <span className="icon text-white-50">
                                  <i className="fas fa-trash"></i>
                                </span>
                                <span className="text">Delete</span>
                              </button>
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                      <button className={'btn float-right btn-icon-split btn-'+theme()} onClick={(e) => submitCart()}>
                        <span className="icon text-white-50">
                          <i className="fas fa-check"></i>
                        </span>
                        <span className="text">Proceed</span>
                      </button>
                    </div>
                  )}
                  {products.length == 0 && (
                    <p>No product in cart</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cart;