import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function UserProfile(props) {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(props.user.address);
  const [gender, setGender] = useState(props.user.gender);
  const [dob, setDob] = useState(props.user.dob);
  const [themeColor, setTheme] = useState(localStorage.getItem("theme"));
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(gender && dob){
      setLoading(true);
      axios.post(global.config.api_server+'user',createFormData({
        gender: gender,
        dob: dob,
        address: address,
        theme: themeColor,
        task: 'basic_profile_client',
        token: localStorage.getItem("token")
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Profile updated');
          localStorage.setItem("theme", themeColor);
          if(themeColor != localStorage.getItem("theme")){
            window.location.reload();
          }
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  const date = new Date(dob);
  let birth_date = date.getFullYear() + "-";
  if((date.getMonth() + 1) < 10){
    birth_date += "0";
  }
  birth_date += (date.getMonth() + 1);
  birth_date += "-";
  if(date.getDate() < 10){
    birth_date += "0";
  }
  birth_date += date.getDate();
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Profile</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Name</label>
              <input type="text" value={props.user.name} className="form-control" disabled={true}/>
            </div>
            <div className="form-group">
              <label>Mobile</label>
              <input type="text" value={props.user.mobile} className="form-control" disabled={true}/>
            </div>
            <div className="form-group">
              <label>Gender *</label>
              <select name="gender" className="form-control" value={gender} id="gender" onChange={(e) => setGender(e.target.value)}>
                <option>--Select--</option>
                <option value="1">Male</option>
                <option value="0">Female</option>
              </select>
            </div>
            <div className="form-group">
              <label>Date of Birth *</label>
              <input type="date" value={birth_date} name="dob" className="form-control" placeholder="Enter DOB" onChange={(e) => setDob(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <input type="email" value={props.user.email} className="form-control" disabled={true}/>
            </div>
            <div className="form-group">
              <label>Theme</label>
              <select name="theme" className="form-control" value={themeColor} onChange={(e) => setTheme(e.target.value)}>
                <option value="0">Blue</option>
                <option value="1">Green</option>
                <option value="2">Light Blue</option>
                <option value="3">Orange</option>
                <option value="4">Red</option>
                <option value="5">Grey</option>
                <option value="6">Dark</option>
              </select>
            </div>
            <div className="form-group">
              <label>Address</label>
              <textarea value={props.user.address} maxLength="100" className="form-control" onChange={(e) => setAddress(e.target.value)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile;