import React, {Component} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard.js';
import Login from './login.js';
import Register from './register.js';
import Profile from './profile.js';
import Categories from './categories.js';
import Category from './category.js';
import Product from './product.js';
import Cart from './cart.js';
import Topnav from '../components/topnav.js';
import Footer from '../components/footer.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';


class Base extends Component {
  
  componentDidMount(){
    const currentTheme = localStorage.getItem("theme");
    axios.get(global.config.api_server+'login?token='+localStorage.getItem("token"))
    .then(res => {
      let status = false;
      if(res.status == 200 && res.data.error === undefined && res.data.user){
        localStorage.setItem('name', res.data.user.name);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('mobile', res.data.user.mobile);
        localStorage.setItem('role', res.data.user.role);
        localStorage.setItem('user_id', res.data.user.user_id);
        localStorage.setItem('supervisor', res.data.user.supervisor);
        localStorage.setItem('theme', res.data.user.theme);
        status = true;
      }
      if(!status){
        localStorage.clear();
        localStorage.setItem('theme', currentTheme);
      }
    }).catch(error => {
      alertService.error("Network error");
      localStorage.clear();
      localStorage.setItem('theme', currentTheme);
    });
  }
  
  render() {
    
    return(
        <BrowserRouter>
          <Alert />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topnav/>
                <Routes>
                  <Route path="*" element={<Dashboard />} />
                  <Route index path='/' element={ <Dashboard /> } ></Route>
                  <Route exact path='/home' element={ <Dashboard /> } ></Route>
                  <Route exact path='/login' element={ <Login /> } ></Route>
                  <Route exact path='/register' element={ <Register /> } ></Route>
                  <Route exact path='/profile' element={ <Profile /> } ></Route>
                  <Route exact path='/profile/:subnav' element={ <Profile /> } ></Route>
                  <Route exact path='/cart' element={ <Cart /> } ></Route>
                  <Route exact path='/categories' element={ <Categories /> } ></Route>
                  <Route exact path='/category/:id' element={ <Category /> } ></Route>
                  <Route exact path='/product/:id' element={ <Product /> } ></Route>
                </Routes>
            </div>
            <Footer/>
          </div>
        </BrowserRouter>
    );
  }
}

export default Base;