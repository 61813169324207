module.exports = global.config = {
    api_server: "https://clipboard.insploreconsultants.com/v1/",
    asset_server: "https://clipboard.insploreconsultants.com",
    //api_server: "http://localhost/v1/",
    filters: "None,Size,Category",
    docType: "Image,Main,PDF,Document,Download",
    price_filter_max: 10000,
    price_filter_min: 0,
    items_per_page: 10,
    home_page_title: 'seo_page_title',
    home_page_description: 'seo_page_description'
};