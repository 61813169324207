import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function ProductSpec(props) {
  const discount = props.product.mrp > 0 && props.product.mrp > props.product.price ? (props.product.mrp-props.product.price) :0;
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Specification(s)</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <table className="table table-striped">
              <tbody>
              {props.product.quantity > 0 ? (
                <tr>
                  <th>Quantity Available</th>
                  <td><p>{props.product.quantity}</p></td>
                </tr>
              ):(
                null
              )}
              {props.product.mrp > 0 ? (
                <tr>
                  <th>MRP</th>
                  <td><p>INR {props.product.mrp}</p></td>
                </tr>
              ):(
                null
              )}
              {props.product.price > 0 ? (
                <tr>
                  <th>Price</th>
                  <td><p>INR {props.product.price}</p></td>
                </tr>
              ):(
                null
              )}
              {discount > 0  ? (
                <tr>
                  <th>Discount</th>
                  <td><p>INR {discount}</p></td>
                </tr>
              ):(
                null
              )}
              {props.product.attributes.map(attr =>
                <tr>
                  <th>{attr.label}</th>
                  <td dangerouslySetInnerHTML={{__html: attr.value}}/>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSpec;