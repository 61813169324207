import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';


function CategoryInfo(props) {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>{props.category.label}</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-4">
            {props.category.documents.map(doc =>
              <span key={doc.document_id}>
                {doc.type === 'Main'? (
                  <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
                ):null}
              </span>
            )}
          </div>
          <div className="col-md-8">
            <div dangerouslySetInnerHTML={{__html: props.category.description}}/>
          </div>
          <div className="col-md-12">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all 1s linear"
            transitionDuration={100}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {props.category.documents.map(doc =>
              <span key={doc.document_id}>
                {doc.type === 'Image' || doc.type === 'Main'? (
                  <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
                ):null}
              </span>
            )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryInfo;