import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import CategoryInfo from '../components/categoryInfo.js';
import Progress from '../components/progress.js';
import ProductList from '../components/productList.js';

function Category(props) {
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(global.config.api_server+'category?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const currentCategory = res.data.category;
        if(currentCategory === null || currentCategory === undefined){
          navigate("/categories");
        }else{
          setCategory(currentCategory);
          setLoading(false);
          if(currentCategory.seo_title){
            document.title = currentCategory.seo_title;
          }else if(currentCategory.seo_description){
            document.getElementsByTagName('meta')["description"].content = currentCategory.seo_description;
          }
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/categories");
    });
  }, [id]);
  
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container">
        <CategoryInfo id={id} category={category}/>
        <ProductList tags={category.tags}/>
      </div>
    )
  }
}

export default Category;