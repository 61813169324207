import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import Carousel from 'react-multi-carousel';

function ProductInfo(props) {
  const [wishList, setWishList] = useState(false);

  useEffect(()=>{
    if(props.product.wish_list){
      setWishList(true);
    }
  })

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const handleCart = (event) => {
    event.preventDefault();
    axios.post(global.config.api_server+'product',createFormData({
      id: props.product.product_id,
      task: 'cart',
      quantity: 1,
      token: localStorage.getItem("token")
    })).then(res => {
      if(res.status === 200 && res.data.status === "success"){
        alertService.success('Product added to cart');
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }

  const handleWishList = (event) => {
    event.preventDefault();
    axios.post(global.config.api_server+'product',createFormData({
      id: props.product.product_id,
      task: 'wish_list',
      token: localStorage.getItem("token")
    })).then(res => {
      if(res.status === 200 && res.data.status === "success"){
        if(res.data.result == "added"){
          setWishList(true);
          alertService.success('Product added to wishlist');
        }else{
          setWishList(false);
          alertService.success('Product removed from wishlist');
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }

  const wishListClass = wishList? "text-"+theme() : "";
  const isLoggedIn = localStorage.getItem('token')? true: false;
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        {isLoggedIn &&
          <span>
            {props.product.quantity != 0 ? (
              <button onClick={(e) => handleCart(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
                <span className="icon text-white-50">
                  <i className="fas fa-cart-plus"></i>
                </span>
                <span className="text">Add to Cart</span>
              </button>
            ):(
              <span className={'float-right text-'+theme()}><small>Not Available</small></span>
            )}
            <span className={"float-right wish-list-icon p-2 mr-2 " + wishListClass} onClick={(e) => handleWishList(e)}>
              <i className="fas fa-heart"></i>
            </span>
          </span>
        }
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>{props.product.label}</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-4">
            {props.product.documents.map(doc =>
              <span key={doc.document_id}>
                {doc.type === 'Main'? (
                  <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
                ):null}
              </span>
            )}
          </div>
          <div className="col-md-8">
            <div dangerouslySetInnerHTML={{__html: props.product.description}}/>
            <div className='product-price'>
            <p><strong>Price:</strong> <small>INR {props.product.mrp>0 && (<span className='mrp'>{props.product.mrp}</span>)}</small>{props.product.price}</p>
            </div>
          </div>
          <div className="col-md-12">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all 1s linear"
            transitionDuration={100}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {props.product.documents.map(doc =>
              <span key={doc.document_id}>
                {doc.type === 'Image' || doc.type === 'Main'? (
                  <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
                ):null}
              </span>
            )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductInfo;