import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import ProductTile from './productTile.js';

function WishList(props) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  
  useEffect(()=>{
    setLoading(true);
    axios.get(global.config.api_server+'products?detail=1&task=wish_list&token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setProducts(res.data.products);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });
  }, []);
  
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">        
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Wish List</h6>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          {products.map(p =>
            <div className="col-md-4" key={p.product_id}>
              <ProductTile product={p}/>
            </div>
          )}
          {products.length == 0 ? (
            <p>Nothing in Wish List</p>
          ):null}
        </div>
      </div>
    </div>
  )
}

export default WishList;