import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import Carousel from 'react-multi-carousel';
import ProductTile from '../components/productTile.js';

function Dashboard(props) {
  const [banners, setBanners] = useState([]);
  const [topRankedProducts, setTopRankedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    axios.get(global.config.api_server+'banners?token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setBanners(res.data.banners);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });

    axios.get(global.config.api_server+'products?detail=1&limit=6&token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        setTopRankedProducts(res.data.products);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });

    axios.get(global.config.api_server+'settings?keys='+global.config.home_page_title+','+global.config.home_page_description+'&token='+localStorage.getItem("token")) 
    .then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        for(const set of res.data.settings){
          if(set.token == global.config.home_page_title){
            document.title = set.value;
          }else if(set.token == global.config.home_page_description){
            document.getElementsByTagName('meta')["description"].content = set.value;
          }
        }
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      setLoading(false);
      alertService.error(err);
    });
  }, []);

  const bannerResponsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  };

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className='banner-container mt-1 mb-4'>
          <Carousel
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={bannerResponsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all 1s linear"
              transitionDuration={100}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
            {banners.map(banner =>
              <div key={banner.banner_id} className='banner-item'>
                <img className="img-fluid" src={global.config.asset_server + banner.document.uri}/>
                <div className='content container'>
                  <h1>{banner.heading}</h1>
                  <p>{banner.description}</p>
                  {banner.cta_label && banner.cta_label != "null"? (
                    <Link className={"btn btn-lg btn-"+theme()} to={"/"+banner.cta_type+"/"+banner.cta_label}>{banner.cta_label}</Link>
                  ):null}
                </div>
              </div>
            )}
          </Carousel>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className="card shadow mb-4">
                <div className="card-header py-3">        
                  <h6 className={'mt-2 font-weight-bold text-'+theme()}>Top Rated Products</h6>
                </div>
                <div className="card-body">
                  <div className="row justify-content-center">
                    {topRankedProducts.map(p =>
                      <div className="col-md-4" key={p.product_id}>
                        <ProductTile product={p}/>
                      </div>
                    )}
                    {topRankedProducts.length == 0 && (
                      <p>No Products</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard;