import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { Link } from 'react-router-dom';
import { alertService } from '../services/alert.service.js';
import { Paginator } from '../components/paginator.js';
import { theme } from '../services/helper.js';
import Progress from '../components/progress.js';

function Categories() {
  const [categories, setCategories] = useState({});
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);


  useEffect(()=>{
    axios.get(global.config.api_server+'categories?detail=1&token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.categories;
        setCategories(temp)
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  const paginated_categories = [];
  if(loading){
    return <Progress/>;
  }else{
    let total = 0;
    let counter = 0;
    let offsetCounter = 0;
    for (const u of categories) {
      total++;
      offsetCounter++;
      if (offsetCounter > offset && counter < global.config.items_per_page){
        counter++;
        paginated_categories.push(u);
      }
    }
    
    return(
      <div className="container">
        <div className="row">
        {paginated_categories.map(category =>
          <div className="col-md-3 col-sm-6" key={category.category_id}>
            <Link to={"/category/" + category.identifier}>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className={'font-weight-bold text-'+theme()}>{category.label}</h6>
                </div>
                <div className="card-body">
                  {category.documents.map(doc =>
                    <span key={doc.document_id}>
                      {doc.type === 'Main'? (
                        <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
                      ):null}
                    </span>
                  )}
                </div>
              </div>
            </Link>
          </div>
        )}
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <Paginator
              itemCount={total}
              itemsPerPage={global.config.items_per_page}
              showNextPages={2}
              showPrevPages={2}
              filterTotal={categories.length}
              grandTotal={categories.length}
              onClick={onPageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Categories;