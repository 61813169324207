import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import '../config.js';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';


function ProductTile(props) {

  return(
    <Link className='product-tile' to={"/product/" + props.product.identifier}>
      <div className="card mb-4">
        <div className="card-body">
          {props.product.documents.map(doc =>
            <span key={doc.document_id}>
              {doc.type === 'Main'? (
                <img className="img-fluid" src={global.config.asset_server + doc.uri}/>
              ):null}
            </span>
          )}
        </div>
        <div className="card-header py-1">            
          <h6 className={'font-weight-bold'}>{props.product.label}</h6>
          <h3><small>INR {props.product.mrp>0 && (<span className='mrp'>{props.product.mrp}</span>)}</small>{props.product.price}</h3>
        </div>
      </div>
    </Link>
  )
}

export default ProductTile;