import React, {useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { alertService } from '../services/alert.service.js';
import { theme } from '../services/helper.js';

function Topnav(props) {
  const [categories, setCatgories] = useState({});
  const [loading, setLoading] = useState(true);
  let isLoggedIn = false;
  if(localStorage.getItem("token")){
    isLoggedIn = true;
  }

  useEffect(()=>{
    axios.get(global.config.api_server+'categories?p=Header&token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.categories;
        setCatgories(temp)
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);
  
  if(loading){
    return null;
  }else{
    return(
      <nav className={"navbar navbar-expand-lg bg-"+theme()}>
        <a className="navbar-brand d-flex align-items-center justify-content-center" href="/"><div className="sidebar-brand-icon rotate-n-15"><i className={"fas fa-shopping-cart"}></i></div><div className={"sidebar-brand-text mx-3"}>My Shop</div></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {categories.map(category =>
              <li className="nav-item" key={category.category_id}>
                <Link className={"nav-link"} to={"/category/" + category.identifier}>{category.label}</Link>
              </li>
            )}
            <li className="nav-item">
              <Link className={"nav-link"} to={"/categories"}>More...</Link>
            </li>
          </ul>
          {isLoggedIn &&
          <ul className="navbar-nav ml-auto"> 

            <li className="nav-item dropdown no-arrow">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline small">{localStorage.getItem("name")}</span>
                <i className="fas fa-user fa-lg"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <Link className="dropdown-item" to="/profile">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Link>
                <Link className="dropdown-item" to="/cart">
                  <i className="fas fa-cart-plus fa-sm fa-fw mr-2 text-gray-400"></i>
                  Cart
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
          }
          {!isLoggedIn &&
          <ul className="navbar-nav ml-auto"> 
            <li className="nav-item">
              <Link className={"nav-link"} to={"/login"}>Login</Link>
            </li>
          </ul>
          }
        </div>
      </nav>
    )
  }
}

export default Topnav;